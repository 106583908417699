<template>
  <div class="wrap__contents bet-history--inner">
    <div class="toolbar">
      <div class="toolbar__items searchbar">
        <select-list
          ref="toolbarNav"
          class="toolbar-nav select-list--filter"
          :title="$route.params.type"
          v-model="selectedType"
        >
          <select-option
            value="all"
            name="all"
          >
            <span>All</span>
          </select-option>

          <select-option
            v-for="type  in gameTypes"
            :key="type.id"
            :value="type.name"
            :name="type.name"
          >
            <span>{{type.name}}</span>
          </select-option>
        </select-list>

        <select-list
          ref="toolbarNav"
          class="toolbar-nav select-list--filter"
          :title="$route.params.status"
          v-model="selectedStatus"
        >
          <select-option value="all" name="all">
            <span>all</span>
          </select-option>
          <select-option value="ongoing" name="ongoing">
            <span>ongoing</span>
          </select-option>
          <select-option value="result" name="result">
            <span>result</span>
          </select-option>
          <select-option value="cancelled" name="cancelled">
            <span>cancelled</span>
          </select-option>
          <select-option value="deleted" name="deleted">
            <span>deleted</span>
          </select-option>
        </select-list>

        <search
          class ="search-bar-wrap"
          :dateBy="dateBy"
          :filterBy="filterBy"
          @refresh="search"
          @trigger="trigger"
          @clear="search"
        >
        </search>
      </div>
    </div>

    <div
      class="wrap__inner"
      :class="{'data-loading': showDataLoading}"
    >
      <check-data-list
        v-if="isDataLoading || isEmpty"
        :isLoading="isDataLoading"
        :isEmpty="isEmpty"
      >
      </check-data-list>

      <div class="betslip__items">
        <betslip-card
          v-for="(slip, index) in betHistory"
          :key="index"
          :logs="slip"
          v-if="!isDataLoading"
          @update="update($event)"
        >
          <bets-card
            v-for="(bets, idx) in slip.userBets"
            :key="idx"
            :logs="bets"
            v-if="slip.userBets.length > 0"
          >
          </bets-card>
        </betslip-card>
      </div>

      <pagination
        :total="count"
        :limit="params.rows"
        v-model="params.page"
        :rows="params.rows"
        @page="trigger">
      </pagination>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getStyles } from '@/assets/js/mixins/base/GetStyles'
import { getListStatus } from '@/assets/js/mixins/common/GetListStatus'

import BetslipCard from '@/components/common/betslip/BetslipCard'
import BetsCard from '@/components/common/betslip/BetsCard'
import Pagination from '@/components/base/Pagination'
import Search from '@/components/base/search/Search'
import CheckDataList from '@/components/base/CheckDataList'

export default {
  name: 'Virtual',

  components: {
    BetslipCard,
    BetsCard,
    Pagination,
    Search,
    CheckDataList
  },

  filters: {},

  mixins: [
    getStyles,
    getListStatus
  ],

  data () {
    let params = Object.assign(
      {
        rows: 50,
        page: 1,
        filter_by: '',
        q: '',
        sort_by: 'id',
        sort: 'desc',
        date_from: '',
        date_to: ''
      },
      this.$route.query
    )

    return {
      params,
      filterBy: [
        {
          value: 'country',
          display: 'country'
        },
        {
          value: 'game_name',
          display: 'game name'
        },
        {
          value: 'league',
          display: 'league'
        },
        {
          value: 'home_team',
          display: 'home team'
        },
        {
          value: 'away_team',
          display: 'away team'
        },
        {
          value: 'login_id',
          display: 'login id'
        },
        {
          value: 'login_name',
          display: 'login name'
        },
        {
          value: 'bet_amount',
          display: 'bet amount'
        },
        {
          value: 'win_amount',
          display: 'win amount'
        }
      ],

      dateBy: [
        {
          value: 'play_date',
          display: 'play date'
        },
        {
          value: 'created_at',
          display: 'bet date'
        },
        {
          value: 'result_date',
          display: 'result date'
        }
      ],

      status: '',
      filter: '',

      isDataLoading: true,
      isEmpty: false,
      showDataLoading: false
    }
  },

  computed: {
    ...mapState('bet-history',
      ['betHistory', 'count']),
    ...mapState('game', {gameTypes: 'types'})
  },

  watch: {
    filter () {
      this.resetParams()
      if (this.filter === '') {
        this.search()
      }
    },

    $route ($router) {
      if (!this.showDataLoading) {
        this.showDataLoading = true
      }
      this.search(this.generate($router.query))
    }
  },

  created () {
    this.params = this.generate(this.params)
    this.search()
    this.getTypes()
  },

  methods: {
    ...mapActions('bet-history',
      ['get', 'restore', 'cancel', 'delete']),
    ...mapActions('game', ['getTypes']),

    async resetParams () {
      this.params = {
        page: 1,
        rows: 50,
        sort_by: 'id',
        sort: 'desc',
        filter_by: '',
        q: '',
        date_from: '',
        date_to: ''
      }
    },

    async trigger (q) {
      this.params = q || this.params
      this.$router.push({ query: this.params })
    },

    async search (q) {
      this.params = q || this.params
      this.status = this.$route.params.status

      // == flags reset
      this.isEmpty = false
      if (!this.showDataLoading) {
        this.isDataLoading = true
      }

      let type = this.gameTypes.find((e) => {
        return e.name === this.$route.params.type
      })

      type = type ? type.id : 'all'

      await this.get({
        type,
        status: this.$route.params.status,
        params: this.params
      })

      this.isDataLoading = false
      this.showDataLoading = false

      if (!this.betHistory.length) {
        this.isEmpty = true
      }
    },

    setTableHeight () {
      this.$loaded(() => {
        this.tblConfig.table_h = this.boxwrapConfig.contents_h
      }, 3000)
    }
  }

}
</script>
